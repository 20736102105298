<script>
import api from '@/helpers/apirest/api';
import Modal from "@/components/modals/modal-form.vue";
import Status from "@/helpers/general/status";
import QlkFailedRequestBtn from '@/components/widgets/qlk-failed-request-btn.vue';

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return {
            customer:null,
            customerFormModalId:'customer-form-modal-show',
            failedRequestModalId:'failed-request-modal-show',
            customerIntegrationStatus: Status
        }
    },
    components: {
        Modal,
        QlkFailedRequestBtn,
        
    },
    computed: {
        modalTilte(){
            return `Detalles de Cliente #${(this.customer?.external_id || '')}(${(this.customer?.store?.name || '')})`;
        },
        getSourceDriverImg(){          
            return require(`@/assets/images/drivers/${this.customer?.source_driver?.source_driver_type?.slug}.png`) ;
        },
        getTargetDriverImg(){          
            return require(`@/assets/images/drivers/${this.customer?.target_driver?.target_driver_type?.slug}.png`) ;
        },
        status(){
            return Status.getStatus({itemStatus: this?.customer?.status, type: 'customers'});
        },
    },
    methods: {
        async getData(){
            if (this.id) {
                const response = await api.get({
                    url: `customers/${this.id}`
                });
                this.customer = response?.data?.data;
            }
        },
        shown() {
            this.getData();            
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        onSuccess(){            
            this.getData(); 
        },
    }
};
</script>

<template>
    <div>
        <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Close'">
            <div class="row mb-4" v-if="customer">
                <div class="col-4">
                    <div class="card h-100">
                        <div class="card-body">                            
                            <h4 class="card-title mb-4">Datos generales</h4>
                            <table class="table table-hover mb-0 table-centered table-nowrap">
                                <tbody>
                                <tr>
                                    <th class="text-right">Fecha</th>
                                    <td>{{ customer?.created_at }}</td>
                                </tr>
                                <tr>
                                    <th class="text-right">Estado</th>
                                    <td>{{ status }}</td>
                                </tr>
                                <tr>
                                    <th class="text-right">Cliente</th>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card h-100">
                        <div class="card-body">                            
                            <h4 class="card-title mb-4">Facturación</h4>
                            <div class="mb-2">
                                <h6>{{ customer?.customer_billing?.full_name }}</h6>
                                <div v-if="customer?.customer_billing?.company">{{ customer?.customer_billing?.company }}</div>
                                <div v-if="customer?.customer_billing?.address_1">{{ customer?.customer_billing?.address_1 }}</div>
                                <div v-if="customer?.customer_billing?.address_2">{{ customer?.customer_billing?.address_2 }}</div>
                                <div v-if="customer?.customer_billing?.postcode || customer?.customer_billing?.city">{{ customer?.customer_billing?.postcode }} {{ customer?.customer_billing?.city }}</div>
                                <div v-if="customer?.customer_billing?.state">{{ customer?.customer_billing?.state }}</div>
                                <div v-if="customer?.customer_billing?.country">{{ customer?.customer_billing?.country }}</div>
                            </div>
                            <div class="mb-2">
                                <h6>Dirección de correo electrónico:</h6>
                                <a :href="'mailto:' + customer?.customer_billing?.email">{{ customer?.customer_billing?.email }}</a>
                            </div>
                            <div>
                                <h6>Teléfono:</h6>
                                <a :href="'tel:' + customer?.customer_billing?.phone">{{ customer?.customer_billing?.phone }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card h-100">
                        <div class="card-body">                            
                            <h4 class="card-title mb-4">Envío</h4>
                            <div class="mb-2" v-if="customer?.customer_shipping">
                                <div class="mb-2">
                                    <h6>{{ customer?.customer_shipping?.full_name }}</h6>
                                    <div v-if="customer?.customer_shipping?.company">{{ customer?.customer_shipping?.company }}</div>
                                    <div v-if="customer?.customer_shipping?.address_1">{{ customer?.customer_shipping?.address_1 }}</div>
                                    <div v-if="customer?.customer_shipping?.address_2">{{ customer?.customer_shipping?.address_2 }}</div>
                                    <div v-if="customer?.customer_shipping?.postcode || customer?.customer_shipping?.city">{{ customer?.customer_shipping?.postcode }} {{ customer?.customer_shipping?.city }}</div>
                                    <div v-if="customer?.customer_shipping?.state">{{ customer?.customer_shipping?.state }}</div>
                                    <div v-if="customer?.customer_shipping?.country">{{ customer?.customer_shipping?.country }}</div>
                                </div>
                                <div>
                                    <h6>Teléfono:</h6>
                                    <a :href="'tel:' + customer?.customer_shipping?.phone">{{ customer?.customer_shipping?.phone }}</a>
                                </div>
                            </div>
                            <div v-else>
                                <i>Sin dirección de envío configurada.</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="customer">
                <div class="col-8">
                    <div class="card h-100">
                        <div class="card-body">                            
                            <h4 class="card-title mb-4">Productos</h4>
                            <div class="table-responsive">
                                <table class="table table-hover mb-0 table-centered">
                                    <thead>
                                        <tr>
                                            <th>Artículo</th>
                                            <th>Origen</th>
                                            <th class="text-right">Precio unidad</th>
                                            <th class="text-right">Cantidad</th>
                                            <th class="text-right">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in customer?.customer_items" :key="item.id">
                                        <td>{{ item?.product?.name }}</td>
                                        <td>{{ item?.product?.integration?.name }}</td>
                                        <td class="text-right">{{ (item?.total / item?.quantity) | currency }}</td>
                                        <td class="text-right">{{ item?.quantity }}</td>
                                        <td class="text-right">{{ item?.total | currency }}</td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="4" class="text-right bg-light">Subtotal:</td>
                                            <td class="text-right bg-light">{{ customer?.total | currency }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" class="text-right bg-light">Total:</td>
                                            <td class="text-right bg-light"><h5>{{ customer?.total | currency }}</h5></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card" v-for="integration in customer?.customer_integrations" :key="integration.id">
                        <div class="card-body">
                            <div class="media mb-4">
                                <div class="media-body overflow-hidden">
                                    <h4 class="card-title">{{ integration?.integration?.name }}</h4>
                                    <h5 class="mb-0">{{ integration?.total | currency }}</h5>
                                </div>
                                <div class="text-primary">
                                    <img :src="integration?.integration?.media?.url" :alt="integration?.integration?.name" class="rounded-circle avatar-sm" />
                                </div>
                            </div>
                            <div>
                                <h6>Identificador</h6>
                                <p class="text-muted mb-0" v-if="integration?.external_id">{{ integration?.external_id }}</p>
                                <p class="text-danger mb-0" v-else>Sin identificador</p>
                            </div>
                        </div>
                        <div class="card-body bcustomer-top py-3">
                            <div class="text-truncate d-flex gap-2">
                                <span v-html="customerIntegrationStatus.getStatus({itemStatus: integration?.status, type: 'customer_integration_status', html: true})"></span>
                                <QlkFailedRequestBtn :id="integration?.failed_request_id"></QlkFailedRequestBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>    
</template>